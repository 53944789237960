<!-- 已购课程 -->
<template>
  <div ref="buy_lesson" class="buy_lesson">
    <!-- <p class="top_title">
      <span class="title_border"></span>
      <span class="title_text">已购买课程</span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>
    <!-- 列表 -->
    <div class="page_cnt">
      <div class="jus-spB mar_top">
        <div>
          <div class=""> 已有{{ scoreName }}&nbsp;&nbsp;&nbsp;&nbsp; <router-link :to="{path:'/coin'}"><span style="color:#ee2e2e">查看记录</span></router-link>
          </div>
          <div class="zlb_num">{{ score }}</div>
        </div>
        <div class="line__"></div>
        <div class="jus-start">
          <img src="../../../assets/img/gift_img.png" alt="" class="gift_img">
          <div style="padding-left:20px">
            <div>签到领<span class="red_text">积分</span></div>
            <div>兑换有<span class="red_text">惊喜</span></div>
          </div>
        </div>
        <router-link :to="{path:'/gift'}">
          <div class="main_btn">去兑换</div>
        </router-link>

      </div>
      <div v-show="isLoadOK" class="main_title" style="cursor: pointer;display: flex;justify-content: space-between;">
        <div>
          <span>每日签到&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="main_title_info">{{ signDay?'已签到'+signDay+'天':'暂未有签到记录' }}</span>
        </div>
        <div class="div_card">
          <img src="@/assets/bu_qian_img.png" alt="">
          <span style="flex-shrink: 0;">补签卡 {{cardNum}} 张</span>
        </div>
      </div>
      <div v-show="isLoadOK" class="jus-spB mar_top">
        <div class="jus-start">
          <div v-for="(i,index) in daySigns"
               @click="buQian(i)"
               :key="index" style="margin-right: 17px;cursor:pointer;">
            <div>
              <img v-if="i.is_sign" src="../../../assets/img/signinly.png" alt="" >
              <div v-else class="sign_in_item">
                +{{ i.score }}
              </div>
            </div>
            <div style="margin-top: 3px;">
              <span class="day"
                    v-if="i.is_repair == 1 && i.is_sign == 0"
                    style="color: #ee2e2e;text-decoration:underline">补签</span>
              <span style="text-align: center" v-else>{{ i.day }}天</span>
            </div>
          </div>
        </div>
        <div v-if="isSigned" class="main_btn main_btn_ly">已签到</div>
        <div v-else class="main_btn  " @click="signReqFun">签到</div>
      </div>

      <div v-show="isLoadOK" class="div_box_sign">
        <div class="style_flex div_card_top" style="justify-content: space-between">
          <div class="main_title" style="margin-top: 12px">补签任务</div>
        </div>
        <div class="style_flex div_buqian"  style="justify-content: space-between">
          <div class="style_flex">
            <img src="@/assets/hard_invite.png" alt="">
            <div class="div_content">
              <div class="div_title">邀请好友注册&nbsp;<span class="title_red_text">+{{invite_num}}&nbsp;补签卡</span></div>
              <div class="div_tip">每邀请1位新用户注册，可获得{{invite_num}}张补签卡，好友可获得积分奖励</div>
            </div>
          </div>
          <div class="main_btn" @click="inviteFriend">去邀请</div>
        </div>
      </div>
      <div class="main_title">新人任务</div>
      <div v-for="(item,index) in tasklist " :key="item.act" class="new_pep jus-spB">
        <div class="jus-start">
          <img :src="require('../../../assets/img/img'+(index+1)+'.png')" alt="" class="new_pep_img">
          <div>
            <div class="jus-start">
              <div class="item_title">{{ item.name }}</div>
              <div class="title_red_text">+{{ item.score }}筑龙币</div>
            </div>
            <div class="item_info_text">{{ item.desc }}</div>
          </div>
        </div>
        <div v-if="item.act===1 ">
          <div v-if="item.is_complete" class="main_btn main_btn_ly">已完成</div>
          <el-upload
            v-else
            class="icon_upload"
            action="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <div class="main_btn">去完成</div>
          </el-upload>
        </div>

        <div v-if="item.act===2">
          <div v-if="item.is_complete" class="main_btn main_btn_ly">已完成</div>
          <div v-else class="main_btn" @click="$router.push({name:'RegistInfo',query:{formNewTask:1}})">去完成</div>
        </div>
        <div v-if="item.act===3">
          <div v-if="item.is_complete" class="main_btn main_btn_ly">已完成</div>
          <el-popover v-if="!item.is_complete && !info_is_complete" placement="left" trigger="click" content="请先完善个人信息，才能做此任务">
            <div slot="reference" class="main_btn">去完成</div>
          </el-popover>
          <div v-if="!item.is_complete && info_is_complete" class="main_btn" @click="followListFun">去完成</div>
        </div>
        <div v-if="item.act===4">
          <div v-if="item.is_complete" class="main_btn main_btn_ly">已完成</div>
          <el-popover v-else placement="left" trigger="click">
            <div class="qrcode_box">
              <div class="qrcode_text">筑龙学社服务号</div>
              <img :src="item.qrcode" alt="" class="qrcode_img">
            </div>
            <div slot="reference" class="main_btn">去完成</div>
          </el-popover>
        </div>
        <div v-if="item.act===5">
          <div v-if="item.is_complete" class="main_btn main_btn_ly">已完成</div>
          <el-popover v-if="!item.is_complete && !service_is_complete" placement="left" trigger="click" content="请先关注筑龙服务号，才能做此任务">
            <div slot="reference" class="main_btn">去完成</div>
          </el-popover>
          <el-popover v-if="!item.is_complete && service_is_complete" placement="left" trigger="click">
            <div class="qrcode_box">
              <div class="qrcode_text">筑龙社群</div>
              <img :src="item.qrcode" alt="" class="qrcode_img">
            </div>
            <div slot="reference" class="main_btn">去完成</div>
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 添加关注 -->
    <div v-if="followBoxShow" class="follow_box_mask">
      <div class="box_ follow_box">
        <div class="jus-end">
          <div class="jump_" @click="followBoxShow=false"><i class="el-icon-close"></i></div>
        </div>
        <div class="title_">你可能认识</div>
        <div class="text_box">
          <div class="row_">关注10个可能认识的人，相互交流吧！同时获得<span class="hei_light">{{ followZlbNum }}筑龙币</span>，可兑换海量<sapn class="hei_light">京东好货</sapn>！</div>
        </div>
        <div class="people_list jus-start">
          <div v-for="i in followListArr" :key="i.uid" class="people_ltem jus-start">
            <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="i.avatar" alt="" class="people_ltem_img">
            <div class="people_ltem_content">
              <div class="peo_name">{{ i.username }}</div>
              <div class="jus-spB" style="width:200px">
                <div class="jus-start">
                  <img src="../../../assets/fensi.png" alt="" class="peo_icon">
                  <div class="peo_info_text">{{ i.fans_num }}粉丝</div>
                </div>
                <div v-if="i.is_follow" class="peo_btn peo_btn_ly jus-center">
                  <div class="btn_text">已关注</div>
                </div>
                <div v-else class="peo_btn jus-center" @click="doFollow(i)">
                  <i class="el-icon-plus" style="color:#fff"></i>
                  <div class="btn_text">关注</div>
                </div>
              </div>
              <div class="peo_info_text">{{ i.from }}</div>
            </div>
          </div>
        </div>
        <div class="next_btn finish_btn " @click="finishFun">
          <span>我已经选好{{ selNum }}/10</span>
          <div class="change_peo" @click.stop="changePeo">换一换</div>
        </div>
      </div>
    </div>
    <el-dialog width="420px" top="35vh" :visible.sync="showShareCard">
      <div slot="title" class="dialog-header">
        <div class="title">邀请卡片：</div>
      </div>
      <div class="style_flex" style="justify-content: center;flex-direction: column">
        <img class="img_card" :src="invite_bg_url" alt="">
        <div class="signBtn" style="margin-bottom: 12px">
          <span class="signBtn1" style="padding:  0 12px;background: #ee2e2e" @click="downImg('','邀请卡片')">下载邀请卡片，发送给好友</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import headers from '@/components/topTitle/index.vue'
import { updateIcon, newTaskIndex,repaireSign, getRegImg } from '@/api/mine'
import Cookies from 'js-cookie'
import { getMajorList, addFollow, followList, followGetScore, updateAvatar, signReq } from '@/api/regInfo.js'
export default {
  components: { headers },
  filters: {

  },
  data() {
    return {
      headName: '任务中心',
      score: '',
      scoreName: '',
      tasklist: [],
      followBoxShow: false,
      selNum: 0,
      followQuery: {
        limit: 9,
        page: 1
      },
      isLoadOK:false,
      signDay: 0,
      isSigned: 0,
      info_is_complete: false,
      daySigns: [],
      service_is_complete: false,
      timer: null,
      tips: '',
      cardNum:0,
      showShareCard: false,
      invite_bg_url:'',
      invite_num:0,


    }
  },
  beforeCreate() {
  },
  created() {
    // this.timer = setInterval(() => (this.getInfo()), 5000)
    this.getInfo()
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    downImg() {
      const link = document.createElement('a');
      link.href = this.invite_bg_url;
      link.download = 'invate_card.png'; // 下载后的文件名
      link.click();
    },
    inviteFriend(){
      this.showShareCard = true
    },
    buQian(item){
      if (item.is_repair == 1 && item.is_sign == 0){
        if(this.cardNum >0){
          if(item.is_repair == 1){
            let params = {
              day:item.day
            }
            repaireSign(params).then((res) => {
              if(res.errNo == 0){
                this.$toast("补签成功")
              }
              this.getInfo()
            }).catch((err) => {
              this.getInfo()
            });
          }
        }else {
          this.$message.error('您没有补签卡，请完成下方的补签任务');
        }
      }

    },
    getInfo() {
      this.isLoadOK = false;
      this.isLoadOK = false;

      let url = window.location.origin + '/ucenter/inviteRegister?invite_uid='+Cookies.get('uid')
      console.log(url,"-----")
      getRegImg({url:url}).then(res =>{
        const errNo = res.errNo
        if (errNo === 0) {
          this.invite_bg_url = res.result
        }
      });
      newTaskIndex().then(res => {
        this.isLoadOK = true;
        // let imgList = [require('../../../assets/img/img'+(index+1)'.png')]
        const result = res.result
        this.score = result.score
        this.invite_num = result.invite_num
        this.cardNum = result.cardNum
        this.scoreName = result.scoreName
        this.recordTitle = this.scoreName + '记录'
        this.isSigned = result.is_sign
        this.daySigns = result.daySigns
        this.signDay = result.signDay
        this.tasklist = result.tasklist
        this.info_is_complete = result.tasklist.filter(i => i.name === '完善信息')[0].is_complete
        this.service_is_complete = result.tasklist.filter(i => i.name === '关注筑龙服务号')[0].is_complete
        this.followZlbNum = res.result.tasklist.filter(i => i.name === '添加关注')[0].score
      })
    },
    // 上传
    handleAvatarSuccess(res, file) {
      const param = { filename: res.url }
      updateIcon(param).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.avatar = res.result.result
          // this.$message.success('头像修改成功')
        } else {
          this.$message.error('头像上传失败')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 关注
    doFollow(i) {
      const p = {
        uid: i.uid
      }
      addFollow(p).then(res => {
        if (res.errNo === 0) {
          i.is_follow = 1
          this.selNum++
        }
      })
      console.log(i)
    },
    followListFun() {
      followList(this.followQuery).then(res => {
        this.followListArr = res.result.list
        this.followMaxNum = Math.ceil(res.result.count / this.followQuery.limit)
        this.followBoxShow = true
        console.log(res)
      })
    },
    changePeo() {
      if (this.followQuery.page < this.followMaxNum) this.followQuery.page++
      else this.followQuery.page = 1
      this.followListFun()
    },
    signReqFun() {
      signReq({ uid: Cookies.get('uid') }).then(res => {
        if (res.errNo === 0) this.getInfo()
      })
    },
    finishFun() {
      followGetScore().then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          this.followBoxShow = false
          this.getInfo()
        }
      })
    }
  }
}
</script>


<style lang="less" scoped>
  .buy_lesson {
    width: 965px;
    min-height: 900px;
    position: relative;
    font-family: "PingFangSC-Medium;";
    font-size: 16px;
  }
  .title_red_text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #ff4743;
    letter-spacing: 0;
    padding-left: 15px;
  }
  .page_cnt {
    width: 660px;
    padding-left: 30px;
    .zlb_num {
      font-family: DINAlternate-Bold;
      font-size: 36px;
      color: #222222;
      letter-spacing: 0;
    }
    .line__ {
      background-color: #e6e6e6;
      width: 1px;
      height: 60px;
    }
    .gift_img {
      // width: ;
      display: block;
    }
    .main_title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      letter-spacing: 0;
      text-align: left;
      margin-top: 32px;
      .main_title_info {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
    }
    .sign_in_item {
      line-height: 38px;
      width: 42px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #f63633;
      letter-spacing: 0;
      text-align: center;
      box-sizing: border-box;
      border: 2px solid #ee2e2e;
      border-radius: 50%;
    }
    .new_pep {
      margin-top: 30px;
      .new_pep_img {
        width: 34px;
        display: block;
        margin: 0;
        margin-right: 30px;
      }
      .item_title {
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
      }
      .title_red_text {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ff4743;
        letter-spacing: 0;
        padding-left: 15px;
      }
      .item_info_text {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }
  .jus-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .jus-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jus-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .jus-spB {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .red_text {
    color: #ee2e2e;
  }
  .main_btn {
    background-image: linear-gradient(45deg, #fd3d36 0%, #ee2e2e 100%);
    border-radius: 17.5px;
    width: 84px;
    flex-shrink: 0;
    line-height: 35px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
  .main_btn_ly {
    color: #999;
    background-color: #f4f4f4;
    background-image: none;
    cursor: none;
  }
  .mar_top {
    margin-top: 20px;
  }
  .follow_box_mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
  }
  .follow_box {
    margin: -285px -468px;
    width: 936px;
    height: 570px;
    .finish_btn {
      margin-top: 30px !important;
      position: relative;
      .change_peo {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #ee2e2e;
        letter-spacing: 0;
        text-align: center;
        position: absolute;
        top: 0;
        left: -70px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .people_list {
      flex-wrap: wrap;
      height: 351px;
      .people_ltem:nth-child(3n) {
        margin-right: 0;
      }
      .people_ltem {
        height: 97px;
        margin-top: 20px;
        padding: 11px 10px;
        align-items: flex-start !important;
        border: 1px solid #e1e1e1;
        box-sizing: border-box;
        margin-right: 29px;
        .people_ltem_img {
          width: 46px;
          height: 46px;
          border-radius: 5px;
          margin-right: 10px;
        }
        .people_ltem_content {
          .peo_name {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #333333;
            letter-spacing: 0;
          }
          .peo_icon {
            width: 14px;
            display: block;
            margin: 0;
            margin-right: 5px;
          }
          .peo_btn_ly {
            background-color: #f4f4f4 !important;
            color: #999 !important;
            cursor: default !important;
          }
          .peo_btn {
            .add_img {
              width: 13px;
              display: block;
              margin: 0;
            }
            .btn_text {
              font-family: PingFangSC-Medium;
              font-size: 12px;
              color: #ffffff;
              letter-spacing: 0;
            }
            background-color: #ee2e2e;
            padding: 5px 8px;
            border-radius: 999px;
            cursor: pointer;
          }
          .peo_btn_ly {
            background-color: #f4f4f4 !important;
            .btn_text {
              color: #999999 !important;
            }
          }
        }
      }
    }
    .peo_info_text {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #999999;
      letter-spacing: 0;
    }
  }
  .box_ {
    background-color: #fff;
    box-shadow: 0 1px 16px 0 rgba(51, 51, 51, 0.1);
    z-index: 601;
    padding: 22px 22px;
    box-sizing: border-box;
    .icon_upload {
      text-align: center;
    }
    .jump_ {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #999999;
      letter-spacing: 0;
      text-align: center;
      cursor: pointer;
    }
    .title_ {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #333333;
      letter-spacing: 0;
      text-align: center;
    }
    .user_img_box {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background-color: #e4e4e4;
      margin: 20px auto;
      box-sizing: border-box;
      .photo_img {
        display: block;
        width: 60px;
        margin: 0 auto;

        padding-top: 25px;
      }
      .user_img_ {
        width: 100%;
        height: 100%;
        border-radius: 999px;
        display: block;
        margin: 0;
      }
    }
    .text_box {
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #333333;
      letter-spacing: 0;
      text-align: center;
      line-height: 23px;
      .hei_light {
        color: #ee2e2e;
      }
    }
    .sex_box {
      margin-top: 20px;
      .sex_item {
        margin: 0 10px;
        line-height: 45px;
        width: 128px;
        box-sizing: border-box;
        background-color: #e4e4e4;
        border-radius: 999px;
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        .sex_icon {
          width: 22px;
          display: block;
          margin: 0;
          margin-right: 10px;
        }
      }
      .sex_item_active {
        background-color: #ee2e2e;
      }
    }
    .next_btn {
      background-color: #ee2e2e;
      line-height: 53px;
      width: 345px;
      margin: 0 auto;
      margin-top: 50px;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      cursor: pointer;
    }
  }
  .qrcode_img {
    display: block;
    margin: 0;
    width: 130px;
  }
  .qrcode_text {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 50px;
    text-align: center;
  }
  .div_card{
    display: flex;
    justify-content: start;
    align-items: center;
    img{
      width: 20px;
    }
    span{
      margin-top: 5px;
      font-size: 13px;
      margin-left: 8px;
      color: #96561D;
      font-weight: 600;
    }
  }
  .style_flex {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .signBtn {
    cursor: pointer;
    margin-top: 8px;
    text-align: center;
    .signBtn1 {
      display: inline-block;
      min-width: 220px;
      height: 40px;
      background-color: rgba(236, 107, 68, 1);
      border-radius: 24px;
      padding: 0 12px;
      font-family: PingFangSC-Medium;
      line-height: 40px;
      font-size: 14px;
      color: #ffffff;
    }
  }
  .img_card{
    width: 340px;
    border-radius: 16px;
    background: pink;
  }
  .dialog-header{
    .title{
      width: 120px;
      font-size:18px;
      margin-top: 15px;
    }
  }
  /deep/.el-dialog__header{
    padding: 20px 20px 0px 15px;
    .el-dialog__headerbtn{
      top: 8px;
      right: 16px;
      font-size: 24px;
    }
  }
  /deep/.el-dialog__body{
    padding: 10px 10px;
  }
  .div_box_sign{
    margin-top: 24px;
    .p1{
      margin-bottom: 0px;
      line-height: 40px;
    }
    .div_buqian{
      margin-top: 12px;
      .div_content{
        margin:0 16px;
        .div_title{
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .div_tip{
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #999999;
          letter-spacing: 0;
          text-align: left;
        }
      }
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
</style>
